import(/* webpackMode: "eager" */ "/www/play4ugames/onlinegames/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/www/play4ugames/onlinegames/components/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager" */ "/www/play4ugames/onlinegames/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/www/play4ugames/onlinegames/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/www/play4ugames/onlinegames/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/www/play4ugames/onlinegames/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/www/play4ugames/onlinegames/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/www/play4ugames/onlinegames/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
